import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

const AboutPage = () => {
  return (
    <Layout activePage="about">
      <Seo title="Адвокатский кабинет Храпова Юрия Николаевича"/>
      <h2 className="main-title">
          Об адвокате
      </h2>
      <div  className="d-flex justify-content-center">
        <StaticImage
          src="../images/about.jpg"
          alt="about"
          placeholder="blurred"
          layout="fixed"
          height={300}
          />
      </div>
      <p className="text-line">&nbsp;</p>
      <p className="text-line"><span className="text-red">адвокат &nbsp;Храпов &nbsp;Юрий &nbsp;Николаевич</span></p>
      <p className="text-line">является членом Адвокатской палаты города Москвы</p>
      <p className="text-line">работающий в Адвокатском кабинете.</p>
      <p className="text-line">&nbsp;</p>
      <p className="text-line"><span className="text-red">юридическая практика с 1997г.</span></p>
      <p className="text-line">юрисконсульт, заместитель генерального директора по правовым вопросам,</p>
      <p className="text-line">исполнительный директор юридической компании.</p>
      <p className="text-line">&nbsp;</p>
      <p className="text-line">юрисконсульт, член Дирекции фонда поддержки предпринимательства,</p>
      <p className="text-line">юрист лизинговой компании,</p>
      <p className="text-line">юрист организаций</p>
      <p className="text-line">&nbsp;</p>
      <p className="text-line"><span className="text-red">адвокатская деятельность с марта 2003г.</span></p>
    </Layout>
  )
}

export default AboutPage
